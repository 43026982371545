.App {
    display: flex;
    flex-direction: row;
  }

  .stock {
    width: 100%; 
    min-height: 8em;
    background: #eee;
    border: 1px solid black;
  }

  .lundi {
    width: 100%; 
    min-height: 8em;
    background: #aeafd3;
    border: 1px solid black;
  }
  .mardi {
    width: 100%; 
    min-height: 8em;
    background: #aeafd3;
    border: 1px solid black;
  }
  .mercredi {
    width: 100%; 
    min-height: 8em;
    background: #aeafd3;
    border: 1px solid black;
  }
  .jeudi {
    width: 100%; 
    min-height: 8em;
    background: #aeafd3;
    border: 1px solid black;
  }
  .vendredi {
    width: 100%; 
    min-height: 8em;
    background: #aeafd3;
    border: 1px solid black;
  }
  .samedi {
    width: 100%; 
    min-height: 8em;
    background: #67677c;
    border: 1px solid black;
  }
  .dimanche {
    width: 100%; 
    min-height: 8em;
    background: #67677c;
    border: 1px solid black;
  }
  